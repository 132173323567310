<template>
  <div>
    <breadcrumb name="Installment Calculator" title="Installments" subtitle="Edit" @onTitleClicked="$emit('cancel')"/>

    <vx-card title="Truck" class="top">
      <vs-row>
        <vs-col vs-lg="4" vs-sm="12">
          <label for="truck-down-payment" class="input">Down Payment</label>
          <vs-input v-model="truck.dowen_payment_flag" class="input" id="truck-down-payment" icon="%" type="number"
                    icon-after="true"/>
        </vs-col>
        <vs-col vs-lg="4" vs-sm="12">
          <label for="truck-interest-low" class="input">Interest Rate
            <arrow-down-icon size="1x" class="custom-class"/>
          </label>
          <vs-input v-model="truck.interest_percent_low" class="input" id="truck-interest-low" icon="%" type="number"
                    icon-after="true"/>
        </vs-col>
        <vs-col vs-lg="4" vs-sm="12">
          <label for="truck-interest-high" class="input">Interest Rate
            <arrow-up-icon size="1x" class="custom-class"/>
          </label>
          <vs-input v-model="truck.interest_percent_high" class="input" id="truck-interest-high" icon="%" type="number"
                    icon-after="true"/>
        </vs-col>
      </vs-row>
    </vx-card>

    <vx-card title="Passenger" class="base">
      <vs-row>
        <vs-col vs-lg="4" vs-sm="12">
          <label for="passenger-down-payment" class="input">Down Payment</label>
          <vs-input v-model="passenger.dowen_payment_flag" class="input" id="passenger-down-payment" icon="%"
                    type="number"
                    icon-after="true"/>
        </vs-col>
        <vs-col vs-lg="4" vs-sm="12">
          <label for="passenger-interest-low" class="input">Interest Rate
            <arrow-down-icon size="1x" class="custom-class"/>
          </label>
          <vs-input v-model="passenger.interest_percent_low" class="input" id="passenger-interest-low" icon="%"
                    type="number"
                    icon-after="true"/>
        </vs-col>
        <vs-col vs-lg="4" vs-sm="12">
          <label for="passenger-interest-high" class="input">Interest Rate
            <arrow-up-icon size="1x" class="custom-class"/>
          </label>
          <vs-input v-model="passenger.interest_percent_high" class="input" id="passenger-interest-high" icon="%"
                    type="number"
                    icon-after="true"/>
        </vs-col>
      </vs-row>
    </vx-card>
    <vx-card title="" class="base">
      <vs-row>
        <vs-col vs-lg="6" vs-sm="12">
          <label for="insurance-percentage" class="input">Insurance Percentage</label>
          <vs-input v-model="insurance_percentage" class="input" id="insurance-percentage" icon="%"
                    type="number"
                    icon-after="true"/>
        </vs-col>
        <vs-col vs-lg="6" vs-sm="12">
          <label for="percent-flag" class="input">Percentage Flag</label>
          <select id="percent-flag" class="vs-select" v-model="percent_flag.percent">
            <option value="0" selected disabled>Choose Flag</option>
            <option :value="percent" v-for="(percent,index) in percents" :key="index">{{percent}} %</option>
          </select>
        </vs-col>
      </vs-row>
      <div class="optionBtns">
        <vs-button
          class="mr-5 save"
          icon-pack="feather"
          icon="icon-save"
          :disabled="invalidForm"
          @click="confirmSaveData"
        >Save
        </vs-button>
        <vs-button
          class="ml-5 cancel"
          type="border"
          color="primary"
          @click="getData"
        >Reset
        </vs-button>
      </div>
    </vx-card>
  </div>
</template>

<script>
  import Breadcrumb from "../../../components/general/breadcrumb";
  import { ArrowDownIcon, ArrowUpIcon } from "vue-feather-icons";

  export default {
    components: { Breadcrumb, ArrowUpIcon, ArrowDownIcon },
    name: "installment",
    data() {
      return {
        percents: ["10", "20", "30", "40", "50", "60", "70", "80", "90"],
        data: {},
        truck: {
          dowen_payment_flag: "",
          interest_percent_low: "",
          interest_percent_high: ""
        },
        passenger: {
          dowen_payment_flag: "",
          interest_percent_low: "",
          interest_percent_high: ""
        },
        percent_flag: {
          id: "",
          percent: "0",
          flag: ""
        },
        insurance_percentage: ""
      }
        ;
    },
    methods: {
      getData() {
        this.$vs.loading();
        this.$httpAdmin.get("config/installment")
          .then(r => {
            this.data = r.data.data;
            this.truck = this.data.truck_installement_configs;
            this.passenger = this.data.passenger_installement_configs;
            this.insurance_percentage = this.data.insurance_percentage;
            this.percent_flag = this.data.percent_flag;
            console.log(this.data);
            this.$vs.loading.close();
          })
          .catch(() => {
            //console.log(e);
            this.$vs.notify({
              title: "Error",
              text: "Couldn't Load Data!",
              color: "danger"
            });
            this.$vs.loading.close();
          });
      },
      confirmSaveData() {
        this.$vs.dialog({
          title: "Confirm",
          text: "You are going to save these data!",
          color: "primary",
          accept: () => this.saveData(),
          type: "confirm"
        });
      },
      saveData() {

        this.$vs.loading();

        let payload = {
          insurance_percentage: this.insurance_percentage,
          passenger_installement_configs: this.passenger,
          truck_installement_configs: this.truck,
          percent: this.percent_flag
        };

        this.$httpAdmin.post(`config/installment`, payload)
          .then(r => {
            console.log(r);
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Success",
              text: "Data Saved Successfully",
              color: "success"
            });
            this.$emit("saved");
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e);
            this.$vs.notify({
              title: "Error",
              text: "Couldn't Save Data!",
              color: "danger"
            });
          });

      }
    },
    computed: {
      invalidForm() {
        if (!this.insurance_percentage
          || !this.passenger.dowen_payment_flag
          || !this.passenger.interest_percent_low
          || !this.passenger.interest_percent_high
          || !this.truck.dowen_payment_flag
          || !this.truck.interest_percent_low
          || !this.truck.interest_percent_high
          || !this.percent_flag.percent
          || this.percent_flag.percent === "0"
        ) {
          return true;
        }
        return false;
      }
    },
    mounted() {
      this.getData();
    }
  };
</script>

<style scoped lang="scss">
  .body {
    border-radius: 10px;
    padding: 20px;
  }

  .optionBtns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
  }

  .input {
    width: 98%;
    margin-left: 20px;
  }

  .emails {
    height: 175px;
  }

  label {
    display: block;
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    margin-top: 20px;
  }

  select {
    width: 100%;
    padding: 8px;
    margin: 0 0 0 20px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.8);
  }

  .top {
    padding-bottom: 20px;
    padding-right: 20px;
  }

  .base {
    margin-top: 20px;
    padding-right: 20px;
  }

</style>
